<template>
  <div class="card shadow-lg rounded-3 py-3" style="border-radius:0; border:none">
    <div class="card-body p-1">
      <h1 class="card-title mb-3 text-dark text-uppercase" style="font-weight:700">
        <span style="color: #0092f7">OTP</span> FORM
      </h1>

      <form @submit.prevent="submit">
        <div class="row">
          <div class="col">
            <div class="form-floating mb-3">
              <input
                  type="text"
                  class="form-control"
                  v-model="otp"
                  id="floatingTextInput1"
                  placeholder="Enter OTP"
                  required
              />
              <label for="floatingTextInput1">Enter OTP</label>
            </div>
          </div>
        </div>

        <div class="text-center">
          <p class="text-danger" v-if="errorMessage">{{ errorMessage }}</p>
          <button :disabled="loading" type="submit" class="btn">
            Submit
            <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          </button>
          <button
              type="button"
              class="btn mt-3"
              :disabled="resendDisabled"
              @click="resendOtp"
          >
            Resend OTP
            <span v-if="resendLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          </button>
          <p class="mt-2 text-muted" v-if="resendTimer > 0">
            You can resend OTP in {{ resendTimer }} seconds.
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "OtpForm",
  data() {
    return {
      otp: "",
      loading: false,
      errorMessage: "",
      resendLoading: false,
      resendDisabled: false,
      resendTimer: 0,
      resendCooldown: 30,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      this.errorMessage = "";

      try {
        const response = await axios.post(`${ticketApi}support/verifyOTP`, { otp: this.otp });

        let supportAgent = response.data.username;
        this.$store.dispatch(`recordlogin`, supportAgent)

        localStorage.removeItem("users");
        localStorage.setItem("supportAgent",supportAgent);

        this.$router.push({ name: "pos" });
      } catch (error) {
        console.error("Error verifying OTP:", error);
        this.errorMessage =
            error.response?.data?.message || "Failed to verify OTP. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    async resendOtp() {
      this.resendLoading = true;
      this.errorMessage = "";

      try {
        const encryptedPayload = localStorage.getItem("users");
        if (!encryptedPayload) {
          throw new Error("No user data found in storage.");
        }

        const bytes = CryptoJS.AES.decrypt(encryptedPayload, "your-secret-key");
        const payload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        await axios.post(`${ticketApi}support/requestOTP`, payload);
        this.startResendCooldown();
      } catch (error) {
        console.error("Error resending OTP:", error);
        this.errorMessage =
            error.response?.data?.message || "Failed to resend OTP. Please try again.";
      } finally {
        this.resendLoading = false;
      }
    },

    startResendCooldown() {
      this.resendDisabled = true;
      this.resendTimer = this.resendCooldown;

      const interval = setInterval(() => {
        this.resendTimer -= 1;
        if (this.resendTimer <= 0) {
          clearInterval(interval);
          this.resendDisabled = false;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.btn,
.btn:hover {
  background: linear-gradient(90deg, rgba(65, 105, 225, 1) 0%, rgba(30, 144, 255, 1) 100%);
  color: white;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 45px;
}

.card {
  padding-left: 10px;
  padding-right: 10px;
  width: 400px;
  max-width: 95vw;
  margin-top: -3rem !important;
}

.show-password-checkbox {
  margin-bottom: 20px;
}
</style>
