import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/layout/Layout";
import PosLoginView from "@/views/point-of-sale/PosLoginView";
import SelectSubscriptionType from "@/views/setup/SelectSubscriptionType";
import store from "@/store";
import POSView from "@/views/point-of-sale/POSView";
import ViewSaleView from "@/views/point-of-sale/sale/ViewSaleView";
import PaymentView from "@/views/point-of-sale/payment/PaymentView";
import SplitPaymentView from "@/views/point-of-sale/payment/SplitPaymentView";
import ViewInvoicesView from "@/views/point-of-sale/payment/ViewInvoicesView";
import CashupView from "@/views/point-of-sale/floatCashup/CashupView";
import OtpView from "@/views/OtpView.vue";
import {Storage} from "@/services/Storage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "poslogin",
        component: PosLoginView,
    },
    {
        path: '/OTP',
        name: "OtpView",
        component: OtpView,
    },
    {
        path: '/select-subscription-type',
        name: "select-subscription-type",
        component: SelectSubscriptionType,
    },
    {

        path: '/',
        component: Layout,
        children: [
            {
                path: '/pos',
                name: 'pos',
                component: POSView
            },
            {
                path: '/view-sale',
                name: "viewSale",
                component: ViewSaleView,
            },
            {
                path: '/kitchen-orders',
                name: "kitchen-orders",
                component: () => import(/* webpackChunkName: "kitchen-orders" */ '../views/point-of-sale/tables/KitchenOrdersView'),
            },
            {
                path: '/payment',
                name: "payment",
                component: PaymentView
            },

            {
                path: '/split-payment',
                name: "split-payment",
                component: SplitPaymentView
            },
            {
                path: '/view-payment',
                name: "viewPayment",
                component: ViewInvoicesView
            },
            {
                path: '/edit-stock',
                props: true,
                name: "edit-stock",
                component: () => import(/* webpackChunkName: "edit-stock" */ '../views/boffice/stock/StockScreen.vue')
            },

            {
                path: '/create_stock',
                name: "addstock",
                component: () => import(/* webpackChunkName: "addstock" */ '../views/boffice/stock/CreateStockScreen')
            },

            {
                path: '/stock',
                name: "stock",
                component: () => import(/* webpackChunkName: "stock" */ '../views/boffice/stock/StockSearch.vue')
            },
            {
                path: '/smart-invoicing-setup',
                name: 'smart-invoicing-setup',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "smart-invoicing-setup" */ '../views/Finance/Smart-invoicing-setup.vue'),
            },


            {
                path: '/stockAdjustment',
                name: "stockAdjustment",
                component: () => import(/* webpackChunkName: "stockAdjustment" */ '../views/boffice/stock/StockAdjustment')
            },
            {
                path: '/float',
                name: "float",
                component: () => import(/* webpackChunkName: "float" */ '../views/point-of-sale/floatCashup/FloatView.vue'),
            },
            {
                path: '/instruction/:plu',
                props: true,
                name: "instruction",
                component: () => import(/* webpackChunkName: "float" */ '../views/point-of-sale/cookingInstruction/CookingInstruction'),
            },
            {
                path: '/cashup',
                name: "cashup",
                component: CashupView,
            },

            {
                path: '/cashup-records',
                name: "cashup-records",
                component: () => import(/* webpackChunkName: "cashup-records" */ '../views/point-of-sale/floatCashup/CashupRecordsView.vue'),
            },

            {
                path: '/stock-categories',
                name: "stock-categories",
                component: () => import(/* webpackChunkName: "stock-categories" */ '../views/boffice/stock-categories/StockCategoriesView'),
            },

            {
                path: '/stock-take',
                name: "stock-take",
                component: () => import(/* webpackChunkName: "stock-take" */ '../views/boffice/StockTake/StockTakeListView'),
            },
            {
                path: '/stock-available',
                name: "stock-available",
                component: () => import(/* webpackChunkName: "stock-available" */ '../views/boffice/stock/StockOnHand'),
            },

            {
                path: '/run-stock-take',
                name: "run-stock-take",
                component: () => import(/* webpackChunkName: "run-stock-take" */ '../views/boffice/StockTake/StockTakeItemsView'),
            },

            {
                path: '/staff',
                name: "employees",
                component: () => import(/* webpackChunkName: "employees" */ '../views/boffice/Employees/EmployeesView'),
            },

            {
                path: '/Employee-edit/:id',
                name: "employeeEdit",
                component: () => import(/* webpackChunkName: "employeeEdit" */ '../views/boffice/Employees/EmpEditView'),
            },

            {
                path: '/create-Employee',
                name: "createEmployee",
                component: () => import(/* webpackChunkName: "createEmployee" */ '../views/boffice/Employees/EmpAddView'),
            },

            {
                path: '/sales-records',
                name: "salesRecords",
                component: () => import(/* webpackChunkName: "salesRecords" */ '../views/point-of-sale/SalesRecordsView'),
            },
            {
                path: '/settings',
                name: "settings",
                component: () => import(/* webpackChunkName: "settings" */ '../views/boffice/settings/SettingsView'),
            },
            {
                path: '/BackOffice',
                name: "BackOffice",
                component: () => import(/* webpackChunkName: "reports" */ '../views/boffice/settings/BackOffice'),
            },

            {
                path: '/reports',
                name: "reports",
                component: () => import(/* webpackChunkName: "reports" */ '../views/boffice/settings/ReportsView'),
            },

            {
                path: '/view-invoice/:invoice',
                name: "viewInvoice",
                component: () => import(/* webpackChunkName: "viewInvoice" */ '../views/point-of-sale/InvoiceDetailsView'),
            },

            {
                path: '/refund-invoice/:invoice',
                name: "refundInvoice",
                component: () => import(/* webpackChunkName: "refundInvoice" */ '../views/point-of-sale/RefundInvoiceView'),
            },

            {
                path: '/tables',
                name: "tables",
                component: () => import(/* webpackChunkName: "tables" */ '../views/point-of-sale/tables/AllTablesView'),
            },

            {
                path: '/lytsoftapps',
                name: "lytsoftapps",
                component: () => import(/* webpackChunkName: "lytsoftapps" */ '../views/point-of-sale/tables/lytsoftapps'),
            },
            {
                path: '/CreateTicket',
                name: 'CreateTicket',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/point-of-sale/tables/CreateTicket'),
            },
            {
                path: '/ViewOpenTicket',
                name: 'ViewOpenTicket',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/point-of-sale/tables/ViewOpenTicket'),
            },
            {
                path: '/SmartInvoiceSignUp',
                name: 'SmartInvoiceSignUp',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/point-of-sale/tables/SmartInvoiceSignUp'),
            },
            {
                path: '/TicketDetails/:id',
                name: 'TicketDetails',
                component: () => import(/* webpackPrefetch: true  , webpackChunkName: "SystemAdjustments" */ '../views/point-of-sale/tables/TicketDetails'),
            },

            {
                path: '/other-payment-methods',
                name: "other-payment-methods",
                component: () => import(/* webpackChunkName: "other-payment-options" */ '../views/boffice/other-payment/OptionsListView'),
            },

            {
                path: '/select-customer',
                name: "select-customer",
                component: () => import(/* webpackChunkName: "select-customer" */ '../views/point-of-sale/Accounts/SelectCustomerView'),
            },

            {
                path: '/create-debtor',
                name: "create-debtor",
                component: () => import(/* webpackChunkName: "create-debtor" */ '../views/point-of-sale/Accounts/CreateDebtorView'),
            },

            {
                path: '/kazang-providers',
                name: "kazang-providers",
                component: () => import(/* webpackChunkName: "kazang-providers" */ '../views/point-of-sale/Kazang/KazangProvidersListView'),
            },

            {
                path: '/select-supplier',
                name: "select-supplier",
                component: () => import(/* webpackChunkName: "select-supplier" */ '../views/boffice/stock-receive/SupplierSelectView'),
            },

            {
                path: '/create-supplier',
                name: "create-supplier",
                component: () => import(/* webpackChunkName: "create-supplier" */ '../views/boffice/supplier/CreateSupplierView'),
            },

            {
                path: '/select-received-items',
                name: "select-received-items",
                component: () => import(/* webpackChunkName: "select-received-items" */ '../views/boffice/stock-receive/SelectItemsReceived'),
            },

            {
                path: '/stock-transfer',
                name: "stock-transfer",
                component: () => import(/* webpackChunkName: "stock-transfer" */ '../views/boffice/StockTransfer/SelectTransferTypeView'),
            },

            {
                path: '/incoming-stock-transfer',
                name: "incoming-stock-transfer",
                component: () => import(/* webpackChunkName: "incoming-stock-transfer" */ '../views/boffice/StockTransfer/IncomingTransfersView'),
            },

            {
                path: '/view-stock-transfer',
                name: "view-stock-transfer",
                component: () => import(/* webpackChunkName: "view-stock-transfer" */ '../views/boffice/StockTransfer/ViewTransferView'),
            },

            {
                path: '/transfer-items',
                name: "transfer-items",
                component: () => import(/* webpackChunkName: "transfer-items" */ '../views/boffice/StockTransfer/ViewItemsToSendView'),
            },
            {
                path: '/transfer-items-search',
                name: "transfer-items-search",
                component: () => import(/* webpackChunkName: "transfer-items-search" */ '../views/boffice/StockTransfer/SearchSendingItemsView'),
            },

            {
                path: '/select-receiving-store',
                name: "select-receiving-store",
                component: () => import(/* webpackChunkName: "select-receiving-store" */ '../views/boffice/StockTransfer/SelectReceivingStoreView'),
            },

            {
                path: '/resetKazangPassword',
                name: "resetKazangPassword",
                component: () => import(/* webpackChunkName: "resetKazangPassword" */ '../views/setup/PasswordResetView'),
            },
            {
                path: '/subscription-accounts',
                name: 'subscription-accounts',
                component: () => import(/* webpackChunkName: "subscription-accounts" */ '../views/setup/Expired/SubscriptionsAndAccountsView'),
            },


        ]
    },

    {
        path: '/email-settings',
        name: "email-settings",
        component: () => import(/* webpackChunkName: "email-settings" */ '../views/boffice/settings/EmailSetupView'),
    },

    {
        path: '/signup',
        name: "signup",
        component: () => import(/* webpackChunkName: "signup" */ '../views/setup/SignUpView'),
    },

    {
        path: '/create-initial-user',
        name: "create-initial-user",
        component: () => import(/* webpackChunkName: "create-initial-user" */ '../views/boffice/Employees/CreateInitialUserView'),
    },

    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login"*/ '../views/LoginView.vue'),
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "forgot-password"*/ '../views/setup/PasswordResetView'),
    },
    {
        path: '/setup',
        name: 'setup',
        component: () => import(/* webpackChunkName: "setup"*/ '../views/setup/AppSetupLogin.vue')
    },
    {
        path: '/SelectUserView',
        name: 'SelectUserView',
        component: () => import(/* webpackPrefetch: true  , webpackChunkName: "setup"*/ '../views/SelectUserView.vue')
    },

    {
        path: '/initKazangOnline',
        name: 'initKazangOnline',
        component: () => import(/* webpackChunkName: "initKazangOnline"*/ '../views/other/InitTransaction.vue')
    },

    {
        path: '/kazangOnline',
        name: 'kazangOnline',
        component: () => import(/* webpackChunkName: "kazangOnline"*/ '../views/other/KazangOnline.vue')
    },

    {
        path: '/test-success',
        name: 'test-success',
        component: () => import(/* webpackChunkName: "test-success"*/ '../views/other/TestSuccessScreen')
    },
    {
        path: '/test-failed',
        name: 'test-failed',
        component: () => import(/* webpackChunkName: "test-failed"*/ '../views/other/TestFailedScreen')
    },

    {
        path: '/custom-install',
        name: 'custom-install',
        component: () => import(/* webpackChunkName: "custom-install"*/ '../views/other/CustomLoginView')
    },

    {
        path: '/premium-upgrade',
        name: 'premium-upgrade',
        component: () => import(/* webpackChunkName: "premium-upgrade"*/ '../views/other/Upgrade/UpgradeToPremiumView')
    },
    {
        path: '/upgradeSuccess/:id/:numberOfDays',
        name: 'upgradeSuccess',
        component: () => import(/* webpackChunkName: "upgradeSuccess"*/ '../views/other/Upgrade/UpgradeSuccessView')
    },

    {
        path: '/system-expired',
        name: 'system-expired',
        component: () => import(/* webpackChunkName: "system-expired"*/ '../views/setup/Expired/SystemExpired')
    },

    {
        path: '/make-subscription-payment',
        name: 'make-subscription-payment',
        component: () => import(/* webpackChunkName: "make-subscription-payment"*/ '../views/setup/Expired/MonthlyPaymentView')
    },

    {
        path: '/expire-extension',
        name: 'expire-extension',
        component: () => import(/* webpackChunkName: "expire-extension"*/ '../views/setup/Expired/ExtensionSuccess')
    },

    {
        path: '/custom-cart',
        name: 'custom-cart',
        component: () => import(/* webpackChunkName: "custom-cart"*/ '../views/other/CustomerCart')
    },
    {
        path: '*',
        name: "notfound",
        component: () => import(/* webpackChunkName: "payment-view" */ '../views/point-of-sale/PosLoginView.vue')
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

router.beforeEach(async (to, from, next) => {
    let exceptions = routeExceptions;
    if (!exceptions.includes(to.name)) {
        let res = await auth.isAdminLoggedIn()
        if (!res) {
            next({name: 'login'})
        } else {
            const IsItABackButton = window.popStateDetected
            window.popStateDetected = false;

            if (IsItABackButton && from.name === `system-expired`) {
                next(false)
                return responses.showWarning(`System Expired`)
            }else if (from.name === `viewSale` || from.name === `pos`){
                let saleLines = store.getters.getSaleLines;
                let proceed = true;
                let descriptions = "";
                for (let i = 0; i < saleLines.length; i++){
                    let qty = saleLines[i].Qty+``;
                    let description = saleLines[i].Description;
                    if (qty === `` || qty.trim() === ``) {
                        proceed = false;
                        descriptions += `\n - `+description+"\n";
                    }
                }
                if (!proceed){
                    next(false);
                   return responses.showWarning(`Item(s) ${descriptions} Needs a QTY`)
                }
            }
            // if (!store.getters.isConnected) {
            //     store.dispatch("connectWebSocket").catch((error) => {
            //         console.error("WebSocket connection failed:", error);
            //     });
            // }
            next()
        }
    } else {
        next()
    }
})


export default router
