<template>
  <div id="app">
    <Loader/>
    <router-view/>
  </div>
</template>
<script>
import Loader from "@/components/layout/Loader";
import {Storage} from "@/services/Storage";

export default {
  components: {Loader},
  beforeMount() {
    if (!routeExceptions.includes(this.name)) {
      this.$store.dispatch('syncData');
    }

  },
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    }
  },
  data() {
    return {
      name: this.$route.name
    }
  },

}
</script>
<style lang="scss">
@import "assets/css/styles";
</style>
