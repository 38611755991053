<template>
  <div class="page">
    <div class="banner">
      <div class="container d-flex justify-content-center"></div>
    </div>
    <div class="container d-flex justify-content-center">
      <Otp/>
    </div>

  </div>
</template>

<script>
import Otp from "@/components/Otp.vue";


export default {
  name: "OtpView",
  components: {Otp}
}
</script>

<style scoped>
.page {
  background: rgb(64, 67, 74);
  background: -moz-linear-gradient(180deg, rgba(64, 67, 74, 1) 14%, rgba(255, 255, 255, 1) 14%);
  background: -webkit-linear-gradient(180deg, rgba(64, 67, 74, 1) 14%, rgba(255, 255, 255, 1) 14%);
  background: linear-gradient(180deg, rgba(64, 67, 74, 1) 14%, rgba(255, 255, 255, 1) 14%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#40434a", endColorstr="#ffffff", GradientType=1);
}

.banner {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: #40434A;
  margin-top: -2px !important;
  max-height: 280px;
}
</style>
