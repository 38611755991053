<template>
  <div v-if="!isOnline" class="network-status">
    You are offline. Some features may not work.
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOnline: navigator.onLine,
      networkCheckInterval: null,
    };
  },
  methods: {
    updateNetworkStatus() {
      this.isOnline = navigator.onLine;
    },
    // Advanced method to check network connectivity
    async checkNetworkConnectivity() {
      try {
        // Perform a quick fetch to a reliable endpoint
        const response = await fetch('https://www.google.com', {
          mode: 'no-cors',
          cache: 'no-store',
          timeout: 5000 // 5 seconds timeout
        });
        this.isOnline = true;
      } catch (error) {
        this.isOnline = false;
      }
    },
    // Continuous network checking method
    startNetworkCheck() {
      // Clear any existing interval to prevent multiple checks
      if (this.networkCheckInterval) {
        clearInterval(this.networkCheckInterval);
      }

      // Check network every 10 seconds
      this.networkCheckInterval = setInterval(() => {
        this.checkNetworkConnectivity();
      }, 10000);
    },
    stopNetworkCheck() {
      if (this.networkCheckInterval) {
        clearInterval(this.networkCheckInterval);
        this.networkCheckInterval = null;
      }
    }
  },
  created() {
    // Add event listeners for network status changes
    window.addEventListener('online', this.updateNetworkStatus);
    window.addEventListener('offline', this.updateNetworkStatus);

    // Start continuous network checking
    this.startNetworkCheck();
  },
  beforeDestroy() {
    // Clean up event listeners
    window.removeEventListener('online', this.updateNetworkStatus);
    window.removeEventListener('offline', this.updateNetworkStatus);

    // Stop network checking interval
    this.stopNetworkCheck();
  }
};
</script>

<style scoped>
.network-status {
  background-color: #ffc107;
  color: #212529;
  padding: 10px;
  text-align: center;

  width: 100%;
  top: 0;
  z-index: 1000;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
</style>