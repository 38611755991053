<template>
  <div class="drawer" v-if="curUser">
    <div class="user-avatar">
      <!--      <div class="avatar"></div>-->
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
        <p>{{ defaultStore }}</p>
      </div>
    </div>
    <div class="routes">
      <ul>
        <li v-if="isUpdateAvailable">
          <a @click="updateApp">
            <div class="icon-box">
              <font-awesome-icon class="icon text-info" icon="fa-solid fa-exclamation-circle"/>
            </div>
            <div class="d-flex flex-column text-center">
              Update Is Available
              <span class="text-sm" @click="updateApp">Click to Update</span>
            </div>
          </a>
        </li>
        <li class="box-container mt-2" v-if="isSmartInvoiceLive && shouldSmartInvoiceSignUp">
          <div class="box red-background-blink mx-2">
            <router-link @click.native="back"  :to="{ name : `smart-invoicing-setup` }" class="text-center route-btn w-100 bg-transparent text-white"> Smart Invoicing Sign up </router-link>
          </div>
        </li>
        <li :class="hasNoFloatAssigned">
          <router-link @click.native="back" :to="{ name : 'pos' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-basket-shopping"/>
            </div>
            Sales
          </router-link>
        </li>
        <li>
          <router-link @click.native="back" :to="{ name : 'kitchen-orders' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="bowl-food"/>
            </div>
            Kitchen Orders
            <span v-if="readyOrders > 0" class="mx-2 bg-warning circular-badge text-white">{{ readyOrders }}</span>
          </router-link>
        </li>

        <li >
          <a @click="salesRecords " class="nav-link">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-receipt"/>
            </div>
            Invoices
          </a>
        </li>
        <li>
          <a @click="loginToOnlineBackOffice">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="business-time"/>
            </div>
            Back Office (Admin)
          </a>
        </li>
        <li :class="setClass">
          <a @click="addstock" class="nav-link">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
            </div>
            Add Stock
          </a>
        </li>

        <li :class="setClass">
          <a @click="stock" class="nav-link">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-magnifying-glass"/>
            </div>
            Edit Stock
          </a>
        </li>
        <li :class="setClass" @click="upgradeToPremium">
          <a @click="stockCategories" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-layer-group"/>
            </div>
            Stock Categories
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>


        <li :class="setClass" @click="upgradeToPremium">
          <a @click="selectSupplier" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-hand-holding-droplet"/>
            </div>
            Stock Receive
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>

        <li  @click="upgradeToPremium">
          <a @click="incomingStockTransfer" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-exchange"/>
            </div>
            Stock Transfer
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>

        <li @click="upgradeToPremium" >
          <a  @click="navigateToStockTake" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-boxes-stacked"/>
            </div>
            Stock Take
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon " icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>
        <li :class="setClass" @click="upgradeToPremium">
          <a @click="createEmployee" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-user-plus"/>
            </div>
            Create Employee
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>


        <li :class="setClass" @click="upgradeToPremium">
          <a @click="employees" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-users"/>
            </div>
            Edit Employee
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>

        <li  @click="upgradeToPremium">
          <a @click="cashup" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-coins"/>
            </div>
            End User Shift
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>


        <li :class="setClass" @click="upgradeToPremium">
          <a @click="cashuprecords" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-coins"/>
            </div>
            Cashup Records
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>


        <li :class="setClass" @click="upgradeToPremium">
          <a @click="otherpaymentmethods" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-comments-dollar"/>
            </div>
            Other Payment Methods
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>

        <li :class="setClass" @click="upgradeToPremium">
          <a @click="lytsoftapps" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="calendar-check"/>
            </div>
            App Store
          </a>
        </li>

        <li  @click="upgradeToPremium">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button"
                      :class="{ collapsed: !isExpanded }"
                      type="button"
                      @click="toggleAccordion"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFiveb"
                      aria-expanded="isExpanded"
                      aria-controls="panelsStayOpen-collapseFiveb">
                <div class="icon-box">
                  <font-awesome-icon class="icon-p" :icon="isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'" />
                </div>
                Support & Tickets
              </button>
            </h2>
            <div id="panelsStayOpen-collapseFiveb" class="accordion-collapse collapse" :class="{ show: isExpanded }">
              <div class="inner">
                <a @click="TicketRoute" v-if="!isZero" class="dropdown-item-bg">
                  <div class="icon-box">
                    <font-awesome-icon class="icon" icon="clipboard-list"/>
                  </div>
                  Report an Issue
                </a>
              </div>
              <div class="inner">
                <a @click="ViewOpenTicket" v-if="!isZero" class="dropdown-item-bg">
                  <div class="icon-box">
                    <font-awesome-icon class="icon" icon="eye"/>
                  </div>
                  View Tickets
                </a>
              </div>
            </div>
          </div>
        </li>


        <li :class="setClass" @click="upgradeToPremium">
          <a @click="reports" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-chart-line"/>
            </div>
            Reports App
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>

        <li :class="setClass" @click="upgradeToPremium">
          <a @click="settings" v-if="!isZero">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-gear"/>
            </div>
            Settings
          </a>
          <div v-else>
            <div class="icon-box float-end">
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
            </div>
          </div>
        </li>
        <li>
          <a @click="logout">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-arrow-right-from-bracket"/>
            </div>
            Log out
          </a>
<!--          <router-link @click.native="back" :to="{ name : 'poslogin' }">-->
<!--            <div class="icon-box">-->
<!--              <font-awesome-icon class="icon" icon="fa-solid fa-arrow-right-from-bracket"/>-->
<!--            </div>-->
<!--            Log out-->
<!--          </router-link>-->
        </li>
      </ul>
      <br>
      <div class="last-upd">
        Last Updated : {{ lastUpdated }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "NavigationDrawer",
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  beforeMount() {
    this.check();
     this.validateLogin();
    auth.isStoreSelected();
  },
  computed: {
    defaultStore() {
      return this.$store.getters.getDefaultStore.StoreName
    },
    lastUpdated() {
      return this.$store.getters.getLasUpdated
    },
    curUser() {
      return this.$store.getters.getCurrentUser
    },

    registrationDetails() {
      return this.$store.getters.getRegistrationDetails;
    },
    isZero() {
      return this.registrationDetails.type === 1;
    },
    isUpdateAvailable() {
      return this.$store.getters.getUpdateAvailability;
    },
    setClass() {
      return this.curUser.Type.toLowerCase() === 'admin' ? '' : 'disabled'
    },
    hasMoreThanOneStore() {
      return this.$store.getters.getStores.length > 1;
    },
    hasNoFloatAssigned() {
      return this.$route.name === "float" ? 'disabled' : '';
    },
    readyOrders() {
      return this.$store.getters.getReadyOrdersCount;
    },
    setMsg() {
      return this.curUser.Type === 'admin' ? '' : 'blocked'
    },
    shouldSmartInvoiceSignUp() {
      return !this.$store.getters.getSmartInvoicingStatus && !this.$store.getters.getDocSubmissionStatus;
    },
    isSmartInvoiceLive() {
      return this.$store.getters.getSmartInvoicingLive;
    },
  },
  methods: {
    async logout() {
      let user = this.curUser.Username;
      if (user === 'Admin') {
        user = localStorage.getItem("supportAgent");
        localStorage.removeItem(`supportAgent`);
      }
      this.$store.dispatch(`recordLogout`,user)
      this.$router.push({name: 'poslogin'})
      this.back();
    },
    check() {
      this.$utils.showLoading();
      return this.$store.dispatch(`getExpiryDateByCustomerID`).finally(()=>this.$utils.hideLoading())
    },
    openDrawer() {
      this.$store.dispatch("openCashDrawer")
    },
    toggleAccordion() {
      this.isExpanded = !this.isExpanded;
    },
    async settings() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Settings');
      if (!isAllowed) return;
      this.$router.push({name: 'settings'});
      this.back()
    },
    async loginToOnlineBackOffice() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, `Boffice`);
      if (isAllowed) await this.$store.dispatch(`openOnlineBackOffice`)
      return this.back();
    },
    salesRecords() {
      return this.$router.push({name: 'salesRecords'});
    },
    async addstock() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'addStock');
      if (!isAllowed) return;
      this.$router.push({name: 'addstock'});
      this.back()
    },
    async stock() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'editStock');
      if (!isAllowed) return;
      this.$router.push({name: 'stock'});
      this.back()
    },

    async reports() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'ReportsPanel');
      if (!isAllowed) return;
      this.$router.push({name: 'reports'});
      this.back()
    },
    async BackOffice() {
      this.$router.push({name: 'BackOffice'});
      this.back()
    },

    otherpaymentmethods() {
      return this.$router.push({name: 'other-payment-methods'});
    },
    lytsoftapps() {
      return this.$router.push({name: 'lytsoftapps'});
    },
    async ViewOpenTicket() {
      this.$router.push({name: 'ViewOpenTicket'})
      this.back()
    },
    async TicketRoute() {
      this.$router.push({name: 'CreateTicket'})
      this.back()
    },
    async cashuprecords() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'cashUpReports');
      if (!isAllowed) return;
      this.$router.push({name: 'cashup-records'});
      this.back()
    },
    async cashup() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'CompleteCashup');
      if (!isAllowed) return;
      this.$router.push({name: 'cashup'});
      this.back()
    },
    async employees() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'HRPanel');
      if (!isAllowed) return;
      this.$router.push({name: 'employees'});
      this.back()
    },
    async createEmployee() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'CreateEmp');
      if (!isAllowed) return;
      this.$router.push({name: 'createEmployee'});
      this.back()
    },

    async stockCategories() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Department');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-categories'});
      this.back()
    },
    async selectSupplier() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'GRV');
      if (!isAllowed) return;
      this.$router.push({name: 'select-supplier'});
      this.back()
    },

    async incomingStockTransfer() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'franchiseSection');
      if (!isAllowed) return;
      this.$router.push({name: 'incoming-stock-transfer'});
      this.back()
    },
    async navigateToStockTake() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'StockTake');
      if (!isAllowed) return;
      // Call your method to get the route dynamically
      const route = this.$store.getters.getStockTakeRoute;
      // Navigate to the route
      this.$router.push(route);
      this.back()
    },

    async validateLogin() {
      let isLoggedIn = await auth.isCashierLoggedIn();
      if (!isLoggedIn) {
        return this.$router.push({name: 'poslogin'})
      }
    },
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },
    upgradeToPremium() {
      if (this.isZero) {
        return this.$store.dispatch(`upgradeToPremium`);
      }
    },
    updateApp() {
      this.$store.commit(`setUpdateAvailability`, false);
      return window.location.reload(true);
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-link {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: none;
  color: #1c1c1c;

  .icon-box {
    width: 35px;
    margin-right: 10px;
    display: flex;
    justify-content: center;

    .icon {
      font-size: 25px;
      color: #34373C;
    }
  }

  &:hover {
    background: #f1f1f1;
    color: #42b983;
    font-weight: 700;

    .icon-box {
      .icon {
        color: #42b983;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .routes {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;

        a {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          padding-left: 10px;
          text-decoration: none;
          color: #1c1c1c;

          .icon-box {
            width: 35px;
            margin-right: 10px;
            display: flex;
            justify-content: center;

            .icon {
              font-size: 25px;
              color: #34373C;
            }
          }

          &:hover, &.router-link-exact-active {
            background: #f1f1f1;
            color: #42b983;
            font-weight: 700;

            .icon-box {
              .icon {
                color: #42b983;
              }
            }
          }
        }
      }
    }
  }

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/KazangBanner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #9ccc65;
          font-weight: 600;
        }
      }

    }
  }
}

ul {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}

.accordion-header button{
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: none;
  color: #1c1c1c;

}
.icon-p{
  width: 35px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: #34373C;
}
.inner {
  background-color: #f0f0f0; /* Light gray background */
  padding-left: 30px; /* Optional: adjust padding as needed */
  border-radius: 4px; /* Optional: add rounded corners */
}
.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
