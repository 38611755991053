import {Storage} from "@/services/Storage";
import axios from "axios";
import router from "@/router";
import TimeManager from "@/services/TimeManager";

export default {
    name: 'billing',
    state: {
        daysToExpire: 30
    },
    getters: {
        getDaysToExpire: (state) => state.daysToExpire,
    },
    mutations: {
        setDaysToExpire: (state, data) => {
            state.daysToExpire = data;
            // console.trace("Mutation called from here:");
        },    },
    actions: {

        getExpiryDateByCustomerID: async (context) => {
            let registrationDetails = await Storage.getRegistrationDetails();
            console.log(registrationDetails)
            // Check if registration details and expiry date exist
            if (!registrationDetails || !registrationDetails.Expire) {
                console.log("No registration details or expiry date available");
                return;
            }

            // Extract expiry date from the registration details
            let Expire = registrationDetails.Expire;

            // Calculate the days to expire
            let diffDays = TimeManager.getDateDiff(TimeManager.getNow(), Expire);

            // Update the state with the number of days to expire
            context.commit('setDaysToExpire', diffDays);

            // Log the updated daysToExpire
            console.log("Days to Expire:", context.getters.getDaysToExpire);

            // If the registration has expired
            if (diffDays <= 0) {
                context.commit('setDaysToExpire', 0);
                return Swal.fire({
                    title: 'System Expired!',
                    icon: 'error',
                    html: '<p>Please make payment to continue</p>',
                    confirmButtonText: 'Continue',
                }).then(() => {
                    try {
                        router.push({ name: 'system-expired' });
                    } catch (e) {
                        console.error('Routing error:', e);
                    }
                });
            }
        },
        getCustomerBalance: async () => {
            let customerID = await Storage.getCurrentStoreID();
            console.log({customerID})
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getBalance?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        hasOverdueCount: async (context) => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/hasOverdueCount?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getDueInvoices: async () => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getDueInvoice?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getActiveStores: async () => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getActiveStores?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getCustomerQuotes: async (context) => {
            let customerID = await Storage.getCurrentStoreID();
            if (!customerID) return;
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `adminCustomerPanel/getQuotes?customer=` + customerID
                }
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        makeKazangLicensePayment: async (context, payload) => {
            let invoiceReference = payload.invoiceReference;
            return axios.post(`https://lna.abc.co.zm/kazangApi/api/paymentGateWay/makeAndProcessPayment`, payload, {timeout: 1000 * 2 * 60})
                .then(() => {
                    return axios.get(subscriptionApiGET, {
                        params: {
                            route: `paymentCompleted/kazangProcess/${invoiceReference}`
                        }
                    }).then(() => context.dispatch(`getExpiryDateByCustomerID`)).catch(err => {
                        throw new Error(err.response)
                    })
                }).catch(err => errorHandler.tomcatError(err));

        },

        makeVCDLicensePayment: async (context, invoiceReference) => {
            return axios.get(subscriptionApiGET, {
                params: {
                    route: `paymentCompleted/kazangProcessVCD/${invoiceReference}`
                }
            }).then(() => context.dispatch(`getExpiryDateByCustomerID`)).catch(err => errorHandler.tomcatError(err));
        },

    }
}
